// This File will be change when we will support multiple language
// https://react.i18next.com/latest/using-with-hooks#configure-i-18-next

import i18n from 'i18next';
import { initReactI18next } from "react-i18next";

const resources = {
  "en": {
    "translation": {
      "Assignee (Ascending)": "Assigned to (A-Z)",
      "Assignee (Descending)": "Assigned to (Z-A)",
      "Assigner (Ascending)": "Assigned by (A-Z)",
      "Assigner (Descending)": "Assigned by (Z-A)",
      "Category (Ascending)": "Category (A-Z)",
      "Category (Descending)": "Category (Z-A)",
      "CompletedDatetime (Ascending)": "Completed (Most recent)",
      "CompletedDatetime (Descending)": "Completed (Oldest)",
      "CreatedDatetime (Ascending)": "Created (Most recent)",
      "CreatedDatetime (Descending)": "Created (Oldest)",
      "Property (Ascending)": "Property (Z-A)",
      "Property (Descending)": "Property (A-Z)",
      "Status (Ascending)": "Status (A-Z)",
      "Status (Descending)": "Status (Z-A)",
      "Submitter (Ascending)": "Submitted by (A-Z)",
      "Submitter (Descending)": "Submitted by (Z-A)",
      "accepted-label": "Accepted",
      "add-comment-button-label": "Add a comment",
      "agree-to-terms-input-label": "I agree to the privacy policy and terms of use",
      "agreeToTerms-error": "You are required to agree to our terms",
      "apply-filters-button-label": "Apply filters",
      "assign-step-description": "We’ll send your report to a technician who will review it and prepare an action plan.",
      "assign-step-title": "Assign",
      "assigneeOrderByLabel": "Assignee",
      "assignerOrderByLabel": "Assigner",
      "attach-image-input-label": "Attach image (optional)",
      "cancel-button-label": "Cancel",
      "cancelled-label": "Cancelled",
      "categoryOrderByLabel": "Category",
      "comment-body-input-label": "Comment",
      "comment-creation-error": "Comment could not be created",
      "complete-button-label": "Complete",
      "completed-between-filter-input-label": "Completed between",
      "completed-label": "Completed",
      "completedDatetimeOrderByLabel": "Completed",
      "created-between-filter-input-label": "Created between",
      "created-by-filter-input-label": "Created by",
      "created-by-filter-input-placeholder": "Select a user",
      "created-table-header": "Created",
      "createdDatetimeOrderByLabel": "Created",
      "delete-comment-confirmation-message": "Deleting the comment cannot be undone. Would you like to continue?",
      "description-error": "An issue description is required",
      "edit-comment-button-label": "Edit comment",
      "email-tracking-information-input-label": "I would like tracking information emailed to me (Optional)'",
      "end-date-filter-input-label": "End date",
      "entryByKeyAllowed-error": "A selection is required",
      "fields-required-alert": "Please fill all required details.",
      "images-title": "Images",
      "in-progress-button-label": "In progress",
      "information-table-header": "Information",
      "issue-created-alert": "Issue successfully created",
      "issue-description-input-hint": "Give a detailed description of the issue so that your technician can come properly prepared.",
      "issue-description-input-label": "Issue description",
      "issue-description-title": "Issue description",
      "issue-location-input-hint": "Location of the issue at the address. For example, apartment 5.",
      "issue-location-input-label": "Issue location",
      "issues-list-page-title": "Issues",
      "issues-menu-label": "Issues",
      "loading": "Loading",
      "loading-label": "Loading...",
      "location-error": "An issue location is required",
      "location-table-header": "Location",
      "loose-pets-information-input-label": "Please describe the types and number of pets at the location. Are there anyspecial instructions for dealing with them.",
      "loose-pets-input-label": "Are there any loose pets?",
      "loosePets-error": "A selection is required",
      "loosePetsInformation-error": "You are required to provide information about any loose pets ",
      "maximum-image-size-error": "The attached image exceeds the maximum size of",
      "no-results-found": "No results found",
      "no-select-label": "No",
      "not-applicable": "N/A",
      "password-input-label": "Password",
      "pending-label": "Pending",
      "privacy-policy-menu-label": "Privacy policy",
      "property-filter-input-label": "Property",
      "property-filter-input-placeholder": "Select a property",
      "propertyOrderByLabel": "Property",
      "reference-table-header": "Reference",
      "rejected-label": "Rejected",
      "report-issue-button-label": "Report issue",
      "report-issue-menu-label": "Report",
      "report-issue-page-title": "Report an issue",
      "report-step-description": "Report an issue using the online form and we’ll create a unique tracking code for you.",
      "report-step-title": "Report",
      "reset-filters-button-label": "Reset filters",
      "resolve-step-description": "The technician will work to resolve the issue. You can track updates using your tracking code.",
      "resolve-step-title": "Resolve",
      "search-address-input-label": "Address",
      "search-for-address-input-information": "Search for your address to continue",
      "sign-in-button-label": "Sign in",
      "sign-in-menu-label": "Sign in",
      "sign-in-page-title": "Sign in",
      "sign-out-confirm": "You will be signed out. Continue?",
      "sign-out-menu-label": "Sign out",
      "sort-by-input-placeholder": "Sort by",
      "start-date-filter-input-label": "Start date",
      "status-filter-input-label": "Status",
      "status-filter-input-placeholder": "Select a status",
      "status-table-header": "Status",
      "statusOrderByLabel": "Status",
      "steps-section-title": "How the issue tracker works",
      "submitter-email-input-label": "Email",
      "submitter-email-input-placeholder": "Email",
      "submitter-name-input-label": "Name",
      "submitter-name-input-placeholder": "Name",
      "submitter-telephone-input-label": "Telephone number",
      "submitterContactInformation-error": "Your email address is required",
      "submitterName-error": "Your name is required",
      "submitterOrderByLabel": "Submitter",
      "technician-main-key-input-hint": "Give permission for a technician to enter the location unsupervised",
      "technician-main-key-input-label": "Technician can enter using main key",
      "technician-visit-inscructions-input-label": "Which days and times are you available for a technician to visit?",
      "technician-visit-instructions-title": "Technician visit instructions",
      "technicianVisitInstructions-error": "You are required to provide visit instructions for your technician",
      "terms-of-service-menu-label": "Terms of service",
      "track-an-issue-title": "Track an issue",
      "track-issue-menu-label": "Track",
      "tracking-code-input-hint": "Enter your tracking code to view the progress of your issue",
      "tracking-code-input-placeholder": "Tracking code",
      "tracking-code-input-required-error": "A tracking code is required",
      "unassigned-label": "Unassigned",
      "unknown-label": "Unknown",
      "urgent-alert-notification": "Notice! If you require urgent attention please contact your property’s local customer support.",
      "username-input-label": "Username",
      "username-or-password-blank-error": "Username or password is blank",
      "username-or-password-incorrect-error": "The username or password is incorrect",
      "view-issue-button-label": "View issue",
      "yes-select-label": "Yes"
    }
  },
  "fi": {
    "translation": {
      "Assignee (Ascending)": "sv-Assigned to (A-Z)",
      "Assignee (Descending)": "sv-Assigned to (Z-A)",
      "Assigner (Ascending)": "sv-Assigned by (A-Z)",
      "Assigner (Descending)": "sv-Assigned by (Z-A)",
      "Category (Ascending)": "Luokka (A-Z)",
      "Category (Descending)": "Luokka (Z-A)",
      "CompletedDatetime (Ascending)": "Valmistunut (viimeisin)",
      "CompletedDatetime (Descending)": "Valmistunut (vanhin)",
      "CreatedDatetime (Ascending)": "Luotu (viimeisin)",
      "CreatedDatetime (Descending)": "Luotu (vanhin)",
      "Property (Ascending)": "Kiinteistö (Z-A)",
      "Property (Descending)": "Kiinteistö (A-Z)",
      "Status (Ascending)": "Status (A-Z)",
      "Status (Descending)": "Status (Z-A)",
      "Submitter (Ascending)": "Toimitettu (A-Z)",
      "Submitter (Descending)": "Toimitettu (Z-A)",
      "accepted-label": "Hyväksytyt",
      "add-comment-button-label": "Lisää kommentti",
      "agree-to-terms-input-label": "Hyväksyn yksityisyyskäytännöt ja käyttöehdot",
      "agreeToTerms-error": "Käyttöehtoihin suostuminen vaaditaan",
      "apply-filters-button-label": "Suodata",
      "assign-step-description": "Lähetämme raporttisi teknikolle. Hän perehtyy siihen ja tekee toimintasuunnitelman.",
      "assign-step-title": "Nimitä",
      "assigneeOrderByLabel": "Valtuutettu",
      "assignerOrderByLabel": "Toimeksiantaja",
      "attach-image-input-label": "Liitä kuva (vaihtoehtoinen)",
      "cancel-button-label": "Peruuta",
      "cancelled-label": "Peruttu",
      "categoryOrderByLabel": "Kategoria",
      "comment-body-input-label": "Kommentti ",
      "comment-creation-error": "Kommenttia ei voitu luoda",
      "complete-button-label": "Valmis",
      "completed-between-filter-input-label": "Suoritettu välillä",
      "completed-label": "Valmistunut",
      "completedDatetimeOrderByLabel": "Valmistunut",
      "created-between-filter-input-label": "Luotu välille",
      "created-by-filter-input-label": "Luonut",
      "created-by-filter-input-placeholder": "Valitse käyttäjä",
      "created-table-header": "Luotu",
      "createdDatetimeOrderByLabel": "Luotu",
      "delete-comment-confirmation-message": "Kommentin poistoa ei voi perua. Haluatko jatkaa?",
      "description-error": "Ongelman kuvaus vaaditaan",
      "edit-comment-button-label": "Muokkaa kommenttia",
      "email-tracking-information-input-label": "Haluan, että seurantatiedot lähetetään minulle sähköpostitse (Vaihtoehtoinen)",
      "end-date-filter-input-label": "Päättymispäivämäärä",
      "entryByKeyAllowed-error": "Valinta vaaditaan",
      "fields-required-alert": "Täytäthän kaikki vaaditut yksityiskohdat.",
      "images-title": "Kuvat",
      "in-progress-button-label": "Käynnissä",
      "information-table-header": "Tiedot",
      "issue-created-alert": "Ongelmaraportti luotu onnistuneesti",
      "issue-description-input-hint": "Kuvaile ongelma yksityiskohtaisesti jotta teknikko voi valmistautua asianmukaisesti.",
      "issue-description-input-label": "Ongelman kuvaus",
      "issue-description-title": "Ongelman kuvaus",
      "issue-location-input-hint": "Ongelman sijainti osoitteessa. Esimerkiksi, asunto 5.",
      "issue-location-input-label": "Ongelman sijainti",
      "issues-list-page-title": "Ongelmat",
      "issues-menu-label": "sv-Issues",
      "loading": "Lataa",
      "loading-label": "Lataa…",
      "location-error": "Ongelman sijainti vaaditaan",
      "location-table-header": "Sijainti",
      "loose-pets-information-input-label": "Kuinka monta ja millaisia lemmikkejä kohteessa on? Kerro tarvittaessa erityiset lemmikkeihin liittyvät toimintaohjeet.",
      "loose-pets-input-label": "Onko paikalla vapaana olevia lemmikkejä?",
      "loosePets-error": "Valinta vaaditaan",
      "loosePetsInformation-error": "Tieto irrallaan olevista lemmikeistä vaaditaan",
      "maximum-image-size-error": "Liitteen kuva ylittää sallitun koon",
      "no-results-found": "Tuloksia ei löytynyt",
      "no-select-label": "Ei",
      "not-applicable": "Ei saatavilla",
      "password-input-label": "Salasana",
      "pending-label": "Vireillä",
      "privacy-policy-menu-label": "Yksityisyyskäytäntö",
      "property-filter-input-label": "Kiinteistö",
      "property-filter-input-placeholder": "Valitse kiinteistö",
      "propertyOrderByLabel": "Kiinteistö",
      "reference-table-header": "Viite",
      "rejected-label": "Hylätyt",
      "report-issue-button-label": "Raportoi ongelmasta",
      "report-issue-menu-label": "Raportoi",
      "report-issue-page-title": "Raportoi ongelma",
      "report-step-description": "Raportoi ongelmasta käyttäen nettilomaketta ja luomme sinulle yksilöllisen seurantakoodin",
      "report-step-title": "Raportoi ",
      "reset-filters-button-label": "Tyhjennä suodattimet",
      "resolve-step-description": "Teknikko pyrkii ratkaisemaan ongelman. Voit seurata tilannetta seurantakoodisi avulla.",
      "resolve-step-title": "Ratkaise",
      "search-address-input-label": "Osoite",
      "search-for-address-input-information": "Etsi osoitteesi jatkaaksesi",
      "sign-in-button-label": "Kirjaudu sisään",
      "sign-in-menu-label": "Kirjaudu sisään",
      "sign-in-page-title": "Kirjaudu sisään",
      "sign-out-confirm": "Sinut kirjataan ulos. Haluatko jatkaa?",
      "sign-out-menu-label": "Kirjaudu ulos",
      "sort-by-input-placeholder": "Lajittele",
      "start-date-filter-input-label": "Aloituspäivämäärä",
      "status-filter-input-label": "Tila",
      "status-filter-input-placeholder": "Valitse tila",
      "status-table-header": "Status",
      "statusOrderByLabel": "Tila",
      "steps-section-title": "Miten ongelman seuranta toimii",
      "submitter-email-input-label": "Sähköposti",
      "submitter-email-input-placeholder": "Sähköposti",
      "submitter-name-input-label": "Nimi",
      "submitter-name-input-placeholder": "Nimi",
      "submitter-telephone-input-label": "Puhelinnumero",
      "submitterContactInformation-error": "Sähköpostiosoite vaaditaan",
      "submitterName-error": "Nimi vaaditaan",
      "submitterOrderByLabel": "Lähettäjä",
      "technician-main-key-input-hint": "Anna teknikolle lupa tulla kohteeseen ilman valvontaa.",
      "technician-main-key-input-label": "Teknikko voi tulla sisään yleisavaimella.",
      "technician-visit-inscructions-input-label": "Minä päivinä ja kellonaikoina olet tavattavissa teknikon vierailua varten?",
      "technician-visit-instructions-title": "Ohjeet teknikon vierailua varten",
      "technicianVisitInstructions-error": "Vierailuohjeet teknikolle vaaditaan",
      "terms-of-service-menu-label": "Käyttöehdot",
      "track-an-issue-title": "Jäljitä ongelma",
      "track-issue-menu-label": "Jäljitä",
      "tracking-code-input-hint": "Syötä seurantakoodisi niin näet ongelman tilan",
      "tracking-code-input-placeholder": "Seurantakoodi",
      "tracking-code-input-required-error": "Seurantakoodi vaaditaan",
      "unassigned-label": "sv-Unassigned",
      "unknown-label": "Tuntematon",
      "urgent-alert-notification": "Huomio! Jos tarvitset kiireellistä palvelua, ota yhteyttä kiinteistön paikalliseen asiakaspalveluun.",
      "username-input-label": "Käyttäjänimi",
      "username-or-password-blank-error": "Käyttäjänimi tai salasana on tyhjä",
      "username-or-password-incorrect-error": "Käyttäjänimi tai salasana on virheellinen",
      "view-issue-button-label": "Katso ongelmaa",
      "yes-select-label": "Kyllä"
    }
  },
  "sv": {
    "translation": {
      "Assignee (Ascending)": "Tilldelad (A-Z)",
      "Assignee (Descending)": "Tilldelad (Z-A)",
      "Assigner (Ascending)": "Tilldelad av (A-Z)",
      "Assigner (Descending)": "Tilldelad av (Z-A)",
      "Category (Ascending)": "Kategori (A-Z)",
      "Category (Descending)": "Kategori (Z-A)",
      "CompletedDatetime (Ascending)": "Avslutad (nyaste)",
      "CompletedDatetime (Descending)": "Avslutad (senaste)",
      "CreatedDatetime (Ascending)": "Skapad (nyaste)",
      "CreatedDatetime (Descending)": "Skapad (senaste)",
      "Property (Ascending)": "Fastighet (Z-A)",
      "Property (Descending)": "Fastighet (A-Z)",
      "Status (Ascending)": "Status (A-Z)",
      "Status (Descending)": "Status (Z-A)",
      "Submitter (Ascending)": "Skapad av (A-Z)",
      "Submitter (Descending)": "Skapad av (Z-A)",
      "accepted-label": "Accepterad",
      "add-comment-button-label": "Skapa en kommentar",
      "agree-to-terms-input-label": "Jag godkänner personuppgiftspolicyn och användarvillkoren",
      "agreeToTerms-error": "Du måste godkänna våra villkor",
      "apply-filters-button-label": "Använd filter",
      "assign-step-description": "Vi skickar din felanmälan till en tekniker som ser över rapporten och återkommer med en handlingsplan.",
      "assign-step-title": "Tilldela",
      "assigneeOrderByLabel": "Tilldelad",
      "assignerOrderByLabel": "Uppdragsgivare",
      "attach-image-input-label": "Bifoga bild (frivilligt)",
      "cancel-button-label": "Ångra",
      "cancelled-label": "Inställd",
      "categoryOrderByLabel": "Kategori",
      "comment-body-input-label": "Kommentar",
      "comment-creation-error": "Kommentaren kunde inte skapas",
      "complete-button-label": "Utförd",
      "completed-between-filter-input-label": "Utförd mellan",
      "completed-label": "Avslutad",
      "completedDatetimeOrderByLabel": "Utförd",
      "created-between-filter-input-label": "Skapad mellan",
      "created-by-filter-input-label": "Skapad av",
      "created-by-filter-input-placeholder": "Välj användare",
      "created-table-header": "Skapad",
      "createdDatetimeOrderByLabel": "Skapad",
      "delete-comment-confirmation-message": "En borttagen kommentar kan ej ångras. Vill du fortsätta?",
      "description-error": "En felanmälansbeskrivning saknas",
      "edit-comment-button-label": "Redigera kommentaren",
      "email-tracking-information-input-label": "Jag vill gärna få spårningsinformationen via e-post",
      "end-date-filter-input-label": "Slutdatum",
      "entryByKeyAllowed-error": "Ett val krävs",
      "fields-required-alert": "Var vänlig fyll i informationen som fattas",
      "images-title": "Bilder",
      "in-progress-button-label": "Pågående",
      "information-table-header": "Information",
      "issue-created-alert": "Felanmälan har skapats",
      "issue-description-input-hint": "Skriv gärna en detaljerad beskrivning av felet så din tekniker kan komma väl förberedd",
      "issue-description-input-label": "Felanmälansbeskrivning",
      "issue-description-title": "Felanmälansbeskrivning",
      "issue-location-input-hint": "Var finns felet? Till exempel: lägenhet 5. (Vet ni ej ert lägenhets- eller lokalnummer? Det hittar ni på er hyresavi.)  ",
      "issue-location-input-label": "Felets läge",
      "issues-list-page-title": "Felanmälan",
      "issues-menu-label": "Felanmälan",
      "loading": "Laddar",
      "loading-label": "Laddar...",
      "location-error": "Ett specificerad läge för felanmälan saknas",
      "location-table-header": "Plats",
      "loose-pets-information-input-label": "Beskriv gärna vilket antal och vilken typ av husdjur som finns på platsen. Finns det några speciella instruktioner för att hantera dem?",
      "loose-pets-input-label": "Finns det några husdjur?",
      "loosePets-error": "Ett val krävs",
      "loosePetsInformation-error": "Du måste lämna information om det finns lösa husdjur",
      "maximum-image-size-error": "Den bifogade filen överstiger den maximala storleken som är",
      "no-results-found": "Inga resultat hittades",
      "no-select-label": "Nej",
      "not-applicable": "Icke applicerbart",
      "password-input-label": "Lösenord",
      "pending-label": "Pågående",
      "privacy-policy-menu-label": "Personuppgiftspolicy",
      "property-filter-input-label": "Fastighet",
      "property-filter-input-placeholder": "Välj en fastighet",
      "propertyOrderByLabel": "Fastighet",
      "reference-table-header": "Referens",
      "rejected-label": "Tillbakavisad",
      "report-issue-button-label": "Felanmälan",
      "report-issue-menu-label": "Rapportera",
      "report-issue-page-title": "Skapa en felanmälan",
      "report-step-description": "Skapa en felanmälan genom att använda online forumläret så får du en unik spårningskod.",
      "report-step-title": "Rapportera",
      "reset-filters-button-label": "Återställ filter",
      "resolve-step-description": "En tekniker kommer att arbeta med att lösa problemet. Du kan följa felanmälans förlopp genom att använda spårningskoden. ",
      "resolve-step-title": "Lösa",
      "search-address-input-label": "Adress",
      "search-for-address-input-information": "Sök efter din adress för att fortsätta",
      "sign-in-button-label": "Logga in",
      "sign-in-menu-label": "Logga in",
      "sign-in-page-title": "Logga in",
      "sign-out-confirm": "Du kommer att loggas ut. Vill du fortsätta?",
      "sign-out-menu-label": "Logga ut",
      "sort-by-input-placeholder": "Sortera med",
      "start-date-filter-input-label": "Startdatum",
      "status-filter-input-label": "Status",
      "status-filter-input-placeholder": "Välj en status",
      "status-table-header": "Status",
      "statusOrderByLabel": "Status",
      "steps-section-title": "Så här fungerar spårningskoden",
      "submitter-email-input-label": "E-post",
      "submitter-email-input-placeholder": "E-post",
      "submitter-name-input-label": "Namn",
      "submitter-name-input-placeholder": "Namn",
      "submitter-telephone-input-label": "Telefonnummer",
      "submitterContactInformation-error": "Din e-post saknas",
      "submitterName-error": "Ditt namn saknas",
      "submitterOrderByLabel": "Skapad av",
      "technician-main-key-input-hint": "Ge teknikern tillstånd att gå in till platsen själv",
      "technician-main-key-input-label": "Tekniker får gå in med huvudnyckel",
      "technician-visit-inscructions-input-label": "Vilka dagar och vilken tid är du tillgänglig så en tekniker kan besöka platsen?",
      "technician-visit-instructions-title": "Driftteknikers besöksinstruktioner",
      "technicianVisitInstructions-error": "Du måste lämna besöksinstruktioner till din tekniker",
      "terms-of-service-menu-label": "Användarvillkor",
      "track-an-issue-title": "Spåra ett fel",
      "track-issue-menu-label": "Spåra",
      "tracking-code-input-hint": "Skriv in din spårningskod för att se status på din felanmälan",
      "tracking-code-input-placeholder": "Spårningskod",
      "tracking-code-input-required-error": "En spårningskod krävs",
      "unassigned-label": "Otilldelad",
      "unknown-label": "Okänd",
      "urgent-alert-notification": "OBS! Om ditt fel behöver akut uppmärksamhet, vid till exempel en stor vattenläcka, vänligen kontakta din fastighets lokala kundtjänst.",
      "username-input-label": "Användarnamn",
      "username-or-password-blank-error": "Användarnamn eller lösenord fattas",
      "username-or-password-incorrect-error": "Användarnamn eller lösenordet är inkorrekt",
      "view-issue-button-label": "Se felanmälan",
      "yes-select-label": "Ja"
    }
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resources,
    lng: window.localStorage.i18nextLng || 'sv',
    fallbackLng: 'en',
    debug: false,
    react: {
      wait: true
    },
    returnObjects: true,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });


export default i18n;
